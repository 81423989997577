export const en = {
  'app.title': 'Data Copilot',

  common: {
    ok: 'OK',
    cancel: 'Cancel',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
  },

  guest: {
    title: 'To sign in as a guest, please provide your email address.',
    button: 'Continue as guest',
    email: {
      placeholder: 'Enter your email address',
      invalid: 'Invalid email address',
    },
  },

  header: {
    title: 'Data Copilot',
  },

  tabs: {
    chat: 'Chat',
    datasources: 'Data Sources',
    events: 'Events',
    about: 'About',
  },

  chat: {
    create_data_source: 'Create a data source...',
  },

  datasources: {
    empty: {
      title: 'No data sources',
      message:
        'There are no data sources assigned to you. Please contact your administrator.',
    },
  },

  sequences: {
    title: 'Sequence <i>{{id}}</i>',
    summarize: {
      description:
        'This sequence is executed once, at the start of each new discussion. It consists of a single prompt to Copilot.',
      prompts: {
        summarize:
          'This prompt asks Copilot to describe your data model and to suggest questions for the user.',
      },
    },

    question: {
      description:
        'This sequence of prompts is executed for every question to Copilot. It consists of the chained prompts.',
      prompts: {
        augment:
          'This prompt asks Copilot to rephrase your question based on your question. It takes the conversation history and any extra instructions you specify.',
        query:
          'The prompt asks Copilot to build a query statement (SQL) to run on your data base on the question and any extra instructions you specify.',
        interpret:
          'The prompt asks Copilot to interpret the data based on question, the query statement (SQL), the results, and any extra instructions you specify.',
        component:
          'The prompt asks Copilot what type of UI component is best used to display the information.',
      },
    },
  },

  about: {
    languages: {
      supported: 'Supported Languages',
      current: 'Current Language',
      en: 'English',
      fr: 'Français',
    },

    dark_mode: {
      supported: 'Supports Dark Mode',
      light: 'Light',
      dark: 'Dark',
      system: '{{systemMode}} (System)',
    },

    guests: {
      supported: 'Supports Guest Users',
      current: 'User Type',
      external: 'User',
      bee: 'Guest',
    },

    oidc: {
      provider: 'OpenID Connect Provider',
      entraid: 'Microsoft Entra ID',
      keycloak: 'Keycloak',
    },

    theme: 'Theme',

    version: 'Application Version',
  },
};
