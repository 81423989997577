import {
  Stack,
  styled,
  Table as BaseTable,
  TableBody as BaseTableBody,
  TableRow as BaseTableRow,
  TableCell as BaseTableCell,
  TableContainer,
  Paper as BasePaper,
  useColorScheme,
  useMediaQuery,
} from '@mui/material';
import {
  useCurrentLanguage,
  useDecodedToken,
  useLanguages,
} from 'hive-react-utils';
import _fp from 'lodash/fp';
import { useContext, useMemo } from 'react';
import { DataContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { useOidcProviderFromStorage } from 'hive-oidc-react';

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));

const Paper = styled(BasePaper)(({ theme }) => ({
  width: '100%',
  maxWidth: `${theme.breakpoints.values.sm}${theme.breakpoints.unit || 'px'}`,
}));

export const Table = styled(BaseTable)(() => ({}));

export const TableBody = styled(BaseTableBody)(() => ({}));

export const TableRow = styled(BaseTableRow)(({ theme }) => ({
  '.MuiTableBody-root &:nth-child(even)': {
    backgroundColor: theme.palette.grey[100],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

export const TableCell = styled(BaseTableCell)(({ theme }) => ({
  color: theme.palette.primary.main,

  // Border inside the table
  borderRight: `1px solid ${theme.palette.divider}`,

  '.MuiTableBody-root &:first-of-type': {
    width: 'min-content',
  },

  '&:last-child': {
    borderRight: 0,
  },
}));

export function About(): JSX.Element {
  const { t } = useTranslation();
  const { featureFlags, appVersion } = useContext(DataContext);
  const rawLanguages = useLanguages();
  const rawLanguage = useCurrentLanguage();
  const token = useDecodedToken();
  const provider = useOidcProviderFromStorage();
  const { mode } = useColorScheme();
  const preferDark = useMediaQuery('(prefers-color-scheme: dark)');
  const systemMode = t(`about.dark_mode.${preferDark ? 'dark' : 'light'}`);

  const languages = useMemo(
    () =>
      _fp.flow([
        // Multiline,
        _fp.map((l) => t(`about.languages.${l}`)),
        _fp.join(', '),
      ])(rawLanguages),
    [rawLanguages, t]
  );

  const language = useMemo(
    () => t(`about.languages.${rawLanguage}`),
    [rawLanguage, t]
  );

  return (
    <Container className="about">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('about.languages.supported')}</TableCell>
              <TableCell>{languages}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('about.languages.current')}</TableCell>
              <TableCell>{language}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('about.dark_mode.supported')}</TableCell>
              <TableCell>
                {featureFlags.supportDarkMode
                  ? t('common.yes')
                  : t('common.no')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('about.theme')}</TableCell>
              <TableCell>
                {t(`about.dark_mode.${mode || 'light'}`, { systemMode })}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('about.guests.supported')}</TableCell>
              <TableCell>
                {featureFlags.supportGuests ? t('common.yes') : t('common.no')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('about.guests.current')}</TableCell>
              <TableCell>{t(`about.guests.${token?.type}`)}</TableCell>
            </TableRow>

            {token?.type !== 'bee' && provider && (
              <TableRow>
                <TableCell>{t('about.oidc.provider')}</TableCell>
                <TableCell>{t(`about.oidc.${provider.type}`)}</TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>{t('about.version')}</TableCell>
              <TableCell>{appVersion?.version}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
