import { lazy, Suspense } from 'react';
import { Loading } from './Loading';

const Metrics = lazy(() => import('./LazyMetrics'));

export function Events(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <Metrics />
    </Suspense>
  );
}
