import { HeaderTab } from 'hive-mui-utils';
import { useContext, useMemo } from 'react';
import {
  TAB_ABOUT,
  TAB_COPILOT,
  TAB_DATA_SOURCES,
  TAB_EVENTS,
} from '../constants';
import { CopilotContext, ICopilotContext } from 'hive-copilot-react';
import { DataContext } from '../contexts';

export function useTabs(): HeaderTab[] {
  const { hasAdminRole } = useContext<ICopilotContext>(CopilotContext);
  const { hasAnalyticsReaderRole, currentDataSourceId, isConnected } =
    useContext(DataContext);

  return useMemo<HeaderTab[]>(() => {
    const t = [];

    if (isConnected) {
      t.push({
        ...TAB_COPILOT,
        path: currentDataSourceId
          ? `${TAB_COPILOT.path}/${currentDataSourceId}`
          : TAB_COPILOT.path,
      });
    }

    if (hasAdminRole) {
      t.push(TAB_DATA_SOURCES);
    }

    if (hasAnalyticsReaderRole) {
      t.push(TAB_EVENTS);
    }

    if (isConnected) {
      t.push(TAB_ABOUT);
    }

    return t;
  }, [currentDataSourceId, hasAdminRole, hasAnalyticsReaderRole, isConnected]);
}
