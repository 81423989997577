import { Paths } from '../constants';
import { lazy, Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from './Loading';

const BaseDataSources = lazy(() => import('./LazyDataSources'));

export function DataSources(): JSX.Element {
  const navigate = useNavigate();
  const chatWithDataSource = useCallback(
    (dataSourceId: string) => {
      navigate(`${Paths.Copilot}/${dataSourceId}`);
    },
    [navigate]
  );

  return (
    <Suspense fallback={<Loading />}>
      <BaseDataSources chatWithDataSource={chatWithDataSource} />
    </Suspense>
  );
}
