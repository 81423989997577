import { createTheme, ThemeOptions } from '@mui/material';
import { muiThemeOptions } from 'hive-mui-utils';

import backgroundUrl from '../assets/oidc-background.png';

const themeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Display the menu instead of the toggle when there are 2 languages
          '&.language-menu.languages-2': {
            display: 'unset',
          },
          '&.language-toggle.languages-2': {
            display: 'none',
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.oidc.providers, &.main-content-container': {
            backgroundImage: `url('${backgroundUrl}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',

            ...theme.applyStyles('dark', {
              backgroundImage: `linear-gradient(
                to bottom right,
                rgba(from ${theme.palette.primary.dark} r g b / 10%),
                rgba(from ${theme.palette.primary.dark} r g b / 20%)
              )`,
            }),
          },
        }),
      },
    },
  },
};

export const appTheme = createTheme(
  {
    colorSchemes: {
      light: true,
      dark: true,
    },
  },
  muiThemeOptions,
  themeOptions
);
