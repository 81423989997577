import {
  AppVersion,
  FeatureFlags,
  useAppVersion,
  useConnectionState,
  useFeatureFlags,
  useHasRole,
} from 'hive-react-utils';
import { createContext, useMemo, useState } from 'react';
import { useAzureSpeechServices } from '../hooks';
import { ConnectionService } from 'hive-client-utils';

export interface AppFeatureFlags extends FeatureFlags {
  azureSpeechRecognition: boolean;
  disableDataSourceEditing: boolean;
  supportGuests: boolean;
  supportDarkMode: boolean;
  supportPopupAuthentication: boolean;
}

const DEFAULT_FEATURE_FLAGS: AppFeatureFlags = {
  azureSpeechRecognition: false,
  disableDataSourceEditing: false,
  supportGuests: false,
  supportDarkMode: false,
  supportPopupAuthentication: false,
};

export interface DataContextProps {
  appVersion?: AppVersion;
  hasAnalyticsReaderRole?: boolean;
  featureFlags: AppFeatureFlags;
  currentDataSourceId: string;
  setCurrentDataSourceId: (dataSourceId: string) => void;
  supportSpeechRecognition: boolean;

  connectionState?: ConnectionService.ConnectionState;
  isConnected?: boolean;
}

export const DataContext = createContext<DataContextProps>({
  featureFlags: DEFAULT_FEATURE_FLAGS,
  currentDataSourceId: '',
  setCurrentDataSourceId: () => {},
  supportSpeechRecognition: false,
});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.
  // IMPORTANT2: this context MUST NOT use the Mui nor Copilot context!

  const hasAnalyticsReaderRole = useHasRole('analyticsReader');

  const featureFlags = useFeatureFlags(DEFAULT_FEATURE_FLAGS);
  const supportSpeechRecognition = useAzureSpeechServices(
    featureFlags.azureSpeechRecognition
  );

  const [currentDataSourceId, setCurrentDataSourceId] = useState('');

  const appVersion = useAppVersion();

  const connectionState = useConnectionState();
  const isConnected =
    connectionState === ConnectionService.ConnectionState.CONNECTED ||
    connectionState === ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN;

  const value = useMemo(
    () => ({
      appVersion,
      hasAnalyticsReaderRole,
      featureFlags,
      supportSpeechRecognition,
      currentDataSourceId,
      setCurrentDataSourceId,

      connectionState,
      isConnected,
    }),
    [
      appVersion,
      hasAnalyticsReaderRole,
      featureFlags,
      supportSpeechRecognition,
      currentDataSourceId,
      setCurrentDataSourceId,

      connectionState,
      isConnected,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
