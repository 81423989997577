import { lazy, Suspense } from 'react';
import { Loading } from './Loading';
import { GuestSignIn } from './GuestSignIn';

const BaseOidcProviders = lazy(() => import('./LazyOidcProviders'));

export function OidcProviders(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <BaseOidcProviders>
        <GuestSignIn />
      </BaseOidcProviders>
    </Suspense>
  );
}
