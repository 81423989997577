import { useEffect, useState } from 'react';
import { initializeAzureSpeechServices } from 'hive-copilot-react';

const fetchCredentials = () =>
  fetch('/tokens/azure-speech').then((res) => {
    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    return res.json();
  });

export function useAzureSpeechServices(featureEnabled?: boolean): boolean {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!featureEnabled) {
      return setEnabled(false);
    }

    initializeAzureSpeechServices(fetchCredentials).then(setEnabled);
  }, [featureEnabled]);

  return enabled;
}
