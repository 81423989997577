import { Navigate, Route, Routes } from 'react-router-dom';
import { useDebugValue, useTitleEffect } from 'hive-react-utils';

import { DataContext } from './contexts';

import { COPILOT_CONTEXT, OIDC_CONTEXT_PROPS, Paths } from './constants';

import { Content, MuiContextProvider } from 'hive-mui-utils';

import {
  HeaderTabs,
  HeaderMenuItems,
  Chat,
  DataSources,
  OidcProviders,
  SignInWithOidc,
  Events,
  HeaderMenuControls,
  About,
  Loading,
} from './components';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Suspense, useContext } from 'react';
import {
  CopilotContext,
  CopilotContextProvider,
  ICopilotContext,
} from 'hive-copilot-react';
import { LocationTracker } from 'hive-analytics-react';
import {
  OidcComplete,
  OidcContextProvider,
  OidcPopupMessage,
  usePopupAuthentication,
} from 'hive-oidc-react';
import { appTheme } from './theme';

function Routing(): JSX.Element {
  const { hasAdminRole } = useContext<ICopilotContext>(CopilotContext);
  const { hasAnalyticsReaderRole } = useContext(DataContext);
  return (
    <>
      {/* Logs analytics events about the route of the user goes to. */}
      <LocationTracker />

      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path={`${Paths.Copilot}/:dataSourceId`}
            element={<Content Component={Chat} />}
          />

          <Route path={Paths.Copilot} element={<Content Component={Chat} />} />

          <Route
            path={Paths.DataSources}
            element={
              hasAdminRole === undefined || hasAdminRole ? (
                <Content Component={DataSources} />
              ) : (
                <Navigate to={process.env.PUBLIC_URL} />
              )
            }
          />

          <Route path={Paths.About} element={<Content Component={About} />} />

          <Route
            path={Paths.Events}
            element={
              hasAnalyticsReaderRole === undefined || hasAnalyticsReaderRole ? (
                <Content Component={Events} />
              ) : (
                <Navigate to={process.env.PUBLIC_URL} />
              )
            }
          />

          <Route path={Paths.SigningIn} element={<SignInWithOidc />} />

          <Route
            path={Paths.SignedIn}
            element={<OidcComplete message={OidcPopupMessage.LoggedIn} />}
          />

          <Route
            path={Paths.SignedOut}
            element={<OidcComplete message={OidcPopupMessage.LoggedOut} />}
          />

          <Route path={Paths.Root} element={<Navigate to={Paths.Copilot} />} />

          <Route
            path="*"
            element={<Navigate to={process.env.PUBLIC_URL}></Navigate>}
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default function App() {
  useTitleEffect('app.title');

  const { featureFlags, appVersion, supportSpeechRecognition } =
    useContext(DataContext);
  useDebugValue(appVersion?.version, 'Application Version', '');

  const openPopupAuthentication = usePopupAuthentication();

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />

      <MuiContextProvider
        DisconnectedComponent={OidcProviders}
        TabsComponent={HeaderTabs}
        HeaderMenuItems={HeaderMenuItems}
        HeaderMenuControls={<HeaderMenuControls />}
      >
        <CopilotContextProvider
          {...COPILOT_CONTEXT}
          supportSpeechRecognition={supportSpeechRecognition}
          disableDataSourceEditing={featureFlags?.disableDataSourceEditing}
        >
          <OidcContextProvider
            {...OIDC_CONTEXT_PROPS}
            Controls={HeaderMenuControls}
            loggedOutPath={
              featureFlags.supportPopupAuthentication
                ? Paths.SignedOut
                : Paths.Root
            }
            signInOptions={
              featureFlags.supportPopupAuthentication
                ? {
                    open: openPopupAuthentication,
                  }
                : {}
            }
          >
            <Routing />
          </OidcContextProvider>
        </CopilotContextProvider>
      </MuiContextProvider>
    </ThemeProvider>
  );
}
