import { SignInWithOidc as BaseSignInWithOidc } from 'hive-oidc-react';
import { Stack, styled } from '@mui/material';
import { Paths } from '../constants';

const Container = styled(Stack)(() => ({
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));

export function SignInWithOidc(): JSX.Element {
  return (
    <Container>
      <BaseSignInWithOidc redirectTo={Paths.SignedIn} />
    </Container>
  );
}
